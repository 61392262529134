/* ScrollTo init */
scrollToElement('.js-scroll-to, .js-page-navigation a');

function addScrollClass() {
  $('body').toggleClass('is-scrolled', $(document).scrollTop() > 480);
}
document.addEventListener('scroll', addScrollClass, { passive: true });
document.addEventListener('touchmove', addScrollClass, { passive: true });

function scrollToElement(selector) {
  jQuery(selector).on('click', function(e) {
    var parser = document.createElement('a');
    parser.href = jQuery(this).attr('href');
    var target = jQuery(parser.hash);
    var offset = jQuery(this).data('offset');
    if ( 'undefined' === typeof(offset) && window.matchMedia("(min-width: 45em)").matches) {
      offset = 0;
    } else if ( 'undefined' === typeof(offset) ) {
      offset = 0;
    }
    if (target) {
      e.preventDefault();
      var distance = jQuery(target).offset().top;
      var speed = 1750;
      if ( distance < 500 ) {
        speed = 750;
      } else if ( distance < 1500 ) {
        speed = 1250;
      }
      jQuery('html, body').animate({
        scrollTop: jQuery(target).offset().top - offset
      }, speed, 'easeOutQuart');
    }
  });
}
